import React from "react";
import { useEffect } from "react";
import "../styles/Fleet.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Slider from "react-slick";

import imagee from "../Assets/Learjet60XR_C_2007-min.jpg";
import pic1 from "../Assets/Picture1(2).png";
import pic2 from "../Assets/Picture22.png";
import pic3 from "../Assets/Picture00.png";
import pic4 from "../Assets/Picture32.png";
import newlogoo from "../Assets/Bombardier_vertical_cmyk_white.png";
import imagee1 from "../Assets/Challenger 605 Exterior Above Desert (1)-min.jpg";
import imagee2 from "../Assets/Challenger 605 Interior (1)-min.jpg";
import imagee3 from "../Assets/Challenger 605 Interior Cabin-min.jpg";
import imagee4 from "../Assets/Challenger 605 Exterior Over Clouds (1)-min.jpg";
import imagee5 from "../Assets/Challenger 605 Interior Rear-min.jpg";
import imagee6 from "../Assets/Challenger 605 Exterior Side view (3)-min.jpg";
import imageenew from "../Assets/fractional/camnew.png";
import img2 from "../Assets/Learjet60XR_D_2007 (1)-min.jpg";
import imglr1 from "../Assets/Untitled design (12).png";
import imglr2 from "../Assets/learjet60xr-005-cmyk.jpg";
export default function Fleet()
{
  const settings = {
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  };

  return (
    <div>
      <div className="video-container">
        <video autoPlay loop muted playsInline className="video">
          <source src="/-251d-4503-aedb-3d114d993b95.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <div className="overlay-text-container">
          <div className="overlay-text">Our Fleet</div>
          <div className="overlay-subtext">
            Exclusive Bombardier Aircraft Crafted for Unmatched Luxury and
            Performance{" "}
          </div>
        </div>
      </div>

      <div className="fleetparadiv">
        <p>
          {" "}
          CamJets proposes to use mid-size jets from the Bombardier fleet,
          keeping in mind the travel needs of leaders in Cambodia. <br /> Over time we
          will look at expanding both size and range covered by larger aircraft.
          <br /> <br />
          <strong>Challenger 605 (Based on configuration selected, can accomodate 9-11
            guests)</strong>
          <br />
          <div className="newdiv">
            <div className="sq1">
              <img className="xyz" src={pic1} alt="xyz" />
              <img className="xyz2" src={pic2} alt="xyz" />

            </div>
            <p className="add-foot1">*Under ideal weather and flying condition</p>
            <br /><br />

          </div>
          <strong >Lear 60 XR (Based on configuration selected, can accomodate 6-8 guests)</strong>
          <div className="newdiv">
            <div className="sq1">
              <img className="xyz" src={pic3} alt="xyz" />
              <img className="xyz2" src={pic4} alt="xyz" />

            </div>

          </div>
          <p className="add-foot">*Under ideal weather and flying condition</p>
          <br /> <br /> The aircraft that have been shortlisted are from the
          Bombardier fleet.
          <br />
          <br />
          Bombardier is known Globally for design, build and maintaining the world's
          peak-performing aircraft for the world's most discerning people,
          businesses and governments. The aircraft shortlisted are based on
          travel patterns of business leaders travelling from Cambodia and only
          Bombardier Certified, pre-owned aircraft will be inducted into the fleet.
          <br />
          Premium aircraft, premium experience and premium coverage - that is
          the Bombardier Certified Pre-Owned promise.
          <br />
          <br /> To ensure the highest level of customer satisfaction, only the
          most outstanding pre-owned aircraft will get Bombardier’s stringent
          and quality-obsessed teams stamp of approval.
        </p>
      </div>

      <div className="bluediv1">
        <p className="Bombardierpara">The Bombardier Lear Jet 60 XR</p>
        <br />
        <p className="Learjetpara">
          The Learjet 60 XR is a mid-size cabin, medium-range business jet
          aircraft manufactured by Bombardier Aerospace in Wichita, Kansas.
          <br />
          <br />
          With a range of ~ 4400 km the cabin configuration can comfortably seat
          6-8 guests.
          <div className="blogo">
            <img height="135px" width="180px" src={newlogoo} alt="lg" />
          </div>
        </p>
      </div>

      <div className="carousel-container">
        <Slider {...settings}>
          <div>
            <img src={imagee} alt="Slide 1" className="carousel-image" />
          </div>

          <div>
            <img src={img2} alt="Slide 1" className="carousel-image" />
          </div>
          <div>
            <img src={imglr1} alt="Slide 1" className="carousel-image" />
          </div>
          <div>
            <img src={imglr2} alt="Slide 1" className="carousel-image" />
          </div>
        </Slider>
      </div>

      <div className="bluediv1">
        <p className="Bombardierpara">The Bombardier Challenger 605</p>
        <br />
        <p className="Learjetpara">
          The Bombardier Challenger 605 delivers the perfect balance of comfort,
          space, range and speed thereby providing the utmost convenience for
          its guests.
          <br />
          <br /> With a range of ~ 7400 kms and the widest cabin in its
          category, the Challenger 605’s luxuriously appointed and spacious
          interior comfortably accommodates 9-12 guests.
          <div className="blogo">
            <img height="135px" width="180px" src={newlogoo} alt="lg" />
          </div>
        </p>
      </div>
      <div className="carousel-container">
        <Slider {...settings}>
          {/* <div>
              <img src={imagee} alt="Slide 1" className="carousel-image" />
            </div> */}
          <div>
            <img src={imagee6} alt="Slide 2" className="carousel-image" />
          </div>
          <div>
            <img src={imagee2} alt="Slide 3" className="carousel-image" />
          </div>
          <div>
            <img src={imagee3} alt="Slide 1" className="carousel-image" />
          </div>
          <div>
            <img src={imagee4} alt="Slide 1" className="carousel-image" />
          </div>
          <div>
            <img src={imagee5} alt="Slide 1" className="carousel-image" />
          </div>
          <div>
            <img src={imagee1} alt="Slide 1" className="carousel-image" />
          </div>
          {/* <div>
            <img src={imageenew} alt="Slide 1" className="carousel-image" />
          </div> */}
        </Slider>
      </div>
    </div>
  );
}
