
import React, { useRef, useEffect, useState } from 'react';
import emailjs from "emailjs-com";
import '../styles/ContactUs.css'
import bombad from "../Assets/Altus+Aviation+Challenger+605+Market+Report (1).jpg";


const SERVICE_ID = "service_5ci0ik9";
const TEMPLATE_ID = "template_eudckey";
const PUBLIC_KEY = "Qn2WdgQCpYDKd0pG7";

const ContactUs = () =>
{

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        designation: "",
        organisation: "",
        country: "",
        message: "",
    });
    const [stateMessage, setStateMessage] = useState("");

    const onChange = (e) =>
    {
        let key = e.target.name;
        let value = e.target.value;
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    const sendMail = async (e) =>
    {
        e.preventDefault();
        if (
            formData.name === "" ||
            formData.email === "" ||
            formData.phone === "" ||
            formData.designation === "" ||
            formData.organisation === "" ||
            formData.country === "" ||
            formData.message === ""
        )
        {
            alert("Please Fill All The Required Fields !");
        } else
        {
            try
            {
                const response = await emailjs.send(
                    SERVICE_ID,
                    TEMPLATE_ID,
                    {
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                        designation: formData.designation,
                        organisation: formData.organisation,
                        country: formData.country,
                        message: formData.message,
                    },
                    PUBLIC_KEY
                );

                console.log("Email successfully sent!", response);
                setStateMessage("Mail sent successfully!");

                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    designation: "",
                    organisation: "",
                    country: "",
                    message: "",
                });
            } catch (error)
            {
                console.error("Error sending email:", error);
                setStateMessage("Something went wrong. Please try again later.");
            }
        }
    };



    const videoRef = useRef(null);

    // useEffect(() =>
    // {
    //     const videoElement = videoRef.current;
    //     const handleTimeUpdate = () =>
    //     {

    //         if (videoElement.currentTime >= videoElement.duration / 1.8)
    //         {
    //             videoElement.currentTime = 0;
    //             videoElement.play();
    //         }
    //     };

    //     videoElement.addEventListener('timeupdate', handleTimeUpdate);

    //     return () =>
    //     {
    //         videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    //     };
    // }, []);

    return (
        <>
            <div className="contact-sec">
                {/* <video
                    ref={videoRef}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="video-fix">
                    <source src="/contactVideo.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
                 <img src={bombad} alt="" className="img-fix" />
                
                <div className="overlay-contact">
                    <div className="textA">Contact Us</div>
                </div>
            </div>

            <div className="contact-container">
                <div className="contact-details">
                    <div className="contact-item">
                        <h3>Phone Number</h3>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p> */}
                        <div className="contact-icon-text">
                            <svg className='contact-icon-text-i' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.62 10.79C8.06 13.9 10.1 15.94 13.21 17.38L15.27 15.32C15.68 14.91 16.3 14.77 16.85 14.95C18.31 15.42 19.89 15.68 21.5 15.68C22.33 15.68 23 16.35 23 17.18V21.5C23 22.33 22.33 23 21.5 23C10.07 23 1 13.93 1 2.5C1 1.67 1.67 1 2.5 1H6.82C7.65 1 8.32 1.67 8.32 2.5C8.32 4.11 8.58 5.69 9.05 7.15C9.22 7.7 9.09 8.32 8.68 8.73L6.62 10.79Z" fill="black" />
                            </svg>

                            <p>+855 11 777 464</p>
                        </div>
                        <p style={{marginLeft:"5rem", marginTop:"20px"}}>WA</p>
                        <div className="contact-icon-text">
                            <svg className='contact-icon-text-i' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.62 10.79C8.06 13.9 10.1 15.94 13.21 17.38L15.27 15.32C15.68 14.91 16.3 14.77 16.85 14.95C18.31 15.42 19.89 15.68 21.5 15.68C22.33 15.68 23 16.35 23 17.18V21.5C23 22.33 22.33 23 21.5 23C10.07 23 1 13.93 1 2.5C1 1.67 1.67 1 2.5 1H6.82C7.65 1 8.32 1.67 8.32 2.5C8.32 4.11 8.58 5.69 9.05 7.15C9.22 7.7 9.09 8.32 8.68 8.73L6.62 10.79Z" fill="black" />
                            </svg>

                            <p>+91 77380 70120</p>
                        </div>
                    </div>
                    <div className="contact-item">
                        <h3>Email Address</h3>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p> */}
                        <div className="contact-icon-text">
                            <svg className='contact-icon-text-i' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" fill="black" />
                            </svg>

                            <p>john@camjets.asia</p>
                        </div>
                    </div>
                    {/* <div className="contact-item">
                        <h3>Address</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                        <div className="contact-icon-text">
                            <svg className='contact-icon-text-i' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="black" />
                            </svg>
                            <p>99 Roving St., Big City, PKU 23456</p>
                        </div>
                    </div> */}
                </div>

                <div className="contact-form">
                    <h2>Contact Us</h2>
                    <h1>Get In Touch</h1>
                    <form onSubmit={sendMail}>
                        <div className="form-row">
                            <input
                                type="text"
                                placeholder="Name"
                                name="name"
                                onChange={onChange}
                                value={formData.name}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                name="email"
                                onChange={onChange}
                                value={formData.email}
                                required
                            />
                        </div>
                        <div className="form-row">
                            <input
                                type="number"
                                placeholder="Phone"
                                name="phone"
                                onChange={onChange}
                                value={formData.phone}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Designation"
                                name="designation"
                                onChange={onChange}
                                value={formData.subjectone}
                                required
                            />
                        </div>
                        <div className="form-row">
                            <input
                                type="text"
                                placeholder="Organisation"
                                name="organisation"
                                onChange={onChange}
                                value={formData.organisation}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Country"
                                name="country"
                                onChange={onChange}
                                value={formData.country}
                                required
                            />
                        </div>
                        <textarea
                            placeholder="Message"
                            name="message"
                            onChange={onChange}
                            value={formData.message}
                            required
                        ></textarea>
                        {stateMessage !== "" ? (
                            <p
                                style={{
                                    color: stateMessage === "Mail sent successfully!" ? "green" : "red",
                                    fontSize: "25px",
                                }}
                            >
                                {stateMessage}
                            </p>
                        ) : (
                            <button className="buttonP" type="submit">Submit</button>
                        )}
                    </form>
                </div>


            </div>

        </>
    );
};

export default ContactUs;
