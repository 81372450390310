import React from "react";
import logoim5 from "../Assets/Challenger 605 Exterior Over Clouds (1) (1).jpg";
import logoim4 from "../Assets/fractional/asses.jpg";
import logoim6 from "../Assets/fractional/ivate jey (1).png";
import logoim7 from "../Assets/fractional/ivate jey (2).png";
import logoim8 from "../Assets/fractional/ivate jey (3).png";
import logoim9 from "../Assets/fractional/ivate jey (4).png";
import logoim10 from "../Assets/fractional/ivate jey (5).png";
import camNew from "../Assets/fractional/camnew.png";
import logoim11 from "../Assets/fractional/a-captivating-image-of-a-family-joyfully-flying-on-S8T7OjD9ToeiThPslYzQlw-plfDGaH9REeL1ZCtZXF-Yw.jpeg";
import logoim12 from "../Assets/fractional/a-luxurious-and-spacious-private-jet-with-plush-le-T0fhAnX_Ts6780fstPx3yw-zEvYDQACTAWYxdxxqQReKw.jpeg";
// import logoim13 from "../Assets/fractional/250447.jpg";
import logoim14 from "../Assets/fractional/last.jpg";
import "../styles/FractionalMembership.css";

const FractionalMembership = () =>
{
  return (
    <>
      <div className="fractional-sec">
        <img src={logoim5} alt="" className="img-fix" />
        <div className="overlay-fraction">
          <div className="textAB">
            <h2>Program</h2>
            <p>Efficient private jet usage without the hassles</p>
          </div>
        </div>
      </div>

      <div className="fractional-container">
        <div className="fractional-details">
          <div className="fractional-item">
            <p>
              Owning and operating a private jet is a highly complex, capex
              heavy and manpower intensive business. <br />
              In addition if you use your jet for less than 500-600 hours a
              year, it is a gross waste of your investment. <br />
              Which is why successful business leaders across the world have
              moved to ‘fractional ownership/ membership’ of business jets. A
              cost effective, reliable and safe way to{" "}
              <strong>Fly Private</strong> <br /> <br />
              <strong>
                The benefits of fractional ownership/membership
              </strong>
              <br />
              <br />
              <p style={{ fontStyle: "italic" }}> "Globally, Fractional ownership/membership has been a highly
                efficient corporate business tool for more than 30 years.
                <br />
                Fractional ownership offers the flexibility, convenience, privacy,
                tax- and time-saving benefits of aircraft ownership, without the
                attendant capital expense or management responsibilities. Just buy
                the share size appropriate to your annual flight requirement in a
                suitable aircraft make, model and size. Then pay a fixed amount
                per month and per hour flown, without having to hire personnel,
                pay hangar rent, or attend to the many other details inherent in
                whole aircraft ownership, including variable costs. Fractional
                providers enjoy a remarkable safety record, given the huge number
                of operations they perform”.</p>
              <br /><br />
              <div className="new-add">
                <img className="img-respo" width="900px" height="400px" src={camNew} alt="new" />
              </div>
              <p> The CamJets <span style={{ fontStyle: "italic" }}>Fractional  Membership Program</span> is tailor made for business owners & busy professionals in Cambodia, allowing  them  all the benefits of an aircraft at their disposal without the headaches and hassles of ‘owning & operating’ one.
                <br /><br />
                This program allows you to  ‘use’ the No of hours you have bought. With the aircraft operating  expenses shared amongst  other owners. So you get the benefit of using your time and money more effectively with utmost convenience, transparency without the headaches.</p>



            </p>
          </div>
        </div>

        {/* <div className="fractional-Card">
          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <div className="div-img">
                <img
                  className="wp-post-image default"
                  src={logoim5}
                  alt="VIP Pick-Up"
                />
              </div>

              <figcaption>
                <h4>Customize your itinerary</h4>
                <p className="figcationp">
                  Travel when you want and with whom you want. In ‘Your
                  Aircraft’.
                </p>
              </figcaption>
            </figure>
          </div>

          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <a target="_self">
                <img
                  className="wp-post-image default"
                  src={logoim6}
                  alt="Speedy Check-In"
                />
              </a>
              <figcaption>
                <h4>Time efficient</h4>
                <p className="figcationp">
                  Take advantage of thousands of private airports worldwide and
                  100s in the ASEAN Region.
                </p>
              </figcaption>
            </figure>
          </div>

          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <a target="_self">
                <img
                  className="wp-post-image default"
                  src={logoim7}
                  alt="Private VIP Terminal"
                />
              </a>
              <figcaption>
                <h4>Set your own schedule</h4>
                <p className="figcationp">
                  No more flight delays or cancellations. And, because you
                  determine your schedule, it is extremely rare for ‘Your
                  Aircraft’ to be delayed.
                </p>
              </figcaption>
            </figure>
          </div>

          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <a target="_self">
                <img
                  className="wp-post-image default"
                  src={logoim8}
                  alt="In Flight Services"
                />
              </a>
              <figcaption>
                <h4>Travel in comfort in a highly sanitized space</h4>
                <p className="figcationp">
                  Flying in ‘Your Aircraft’ means goodbye to crowded airports &
                  aircraft.{" "}
                </p>
              </figcaption>
            </figure>
          </div>

          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <a target="_self">
                <img
                  className="wp-post-image default"
                  src={logoim10}
                  alt="In Flight Services"
                />
              </a>
              <figcaption>
                <h4>At your service</h4>
                <p className="figcationp">
                  When you are fly in Your Aircraft, you do not have to sleep at
                  the airport or kill hours between flights. If that is not
                  enough, you should also know that when you fly privately, you
                  would not miss the flight. If ever you are late ‘Your
                  Aircraft’ will be waiting for you.
                </p>
              </figcaption>
            </figure>
          </div>
          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <a target="_self">
                <img
                  className="wp-post-image default"
                  src={logoim11}
                  alt="In Flight Services"
                />
              </a>
              <figcaption>
                <h4>
                  Fly with those you choose to – your team, friends, or family
                </h4>
                <p className="figcationp">
                  The only people inside ‘Your Aircraft’ will be the pilot, your
                  family or your friends or your team. Discuss family matters
                  without fear of a stranger overhearing your conversation or
                  discuss marketing strategies with your team, knowing your
                  competitor is nowhere near you.
                </p>
              </figcaption>
            </figure>
          </div>
          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <a target="_self">
                <img
                  className="wp-post-image default"
                  src={logoim12}
                  alt="In Flight Services"
                />
              </a>
              <figcaption>
                <h4>
                  Fly Private in your ‘Your Aircraft’ at a fraction of the cost
                  of owning & operating one
                </h4>
                <p className="figcationp">
                  Flying in ‘Your Aircraft’ means goodbye to crowded airports &
                  aircraft.{" "}
                </p>
              </figcaption>
            </figure>
          </div>
          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <a target="_self">
                <img
                  className="wp-post-image default"
                  src={logoim13}
                  alt="In Flight Services"
                />
              </a>
              <figcaption>
                <h4>Your aircraft when you need it</h4>
                <p className="figcationp">
                  Since you will be sharing the aircraft with other like-minded
                  individuals, it is important for you to book in advance so
                  that the aircraft is ready when you are.
                </p>
              </figcaption>
            </figure>
          </div>
          <div className="featured-image-box text-align-center normal_hover_style">
            <figure className="sadie default">
              <a target="_self">
                <img
                  className="wp-post-image default"
                  src={logoim9}
                  alt="In Flight Services"
                />
              </a>
              <figcaption>
                <h4>Avoid typical airport problems</h4>
                <p className="figcationp">
                  Stress is present in almost all phases of flying in an
                  airline. Most airlines require passengers to arrive at the
                  airport several hours in advance to check in. After check in,
                  passengers will have to wait for their flight, which can be
                  delayed, or worse, cancelled. On board the aircraft,
                  discomfort can appear in the form of unruly passengers. Then,
                  upon arrival- guess what -you can lose your luggage. When you
                  fly in ‘Your Aircraft’ you fly with people you want. On Time.
                  With your luggage. With minimal waiting.
                </p>
              </figcaption>
            </figure>
          </div>
        </div> */}
      </div>
      <div className="new-conatin">
        <div className="box1 m-fix">
          <div className="div-img">
            <img className="config-img" src={logoim9} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>Set your own schedule: </strong> No more flight delays or cancellations. And, because you determine your schedule, it is extremely rare for <span className="italic"> ‘Your Aircraft’</span> to be delayed. </p>
          </div>

        </div>
        <div className="box1 m-fix">
          <div className="div-img">
            <img className="config-img" src={logoim10} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>Customize your itinerary: </strong>Travel when you want and with whom you want. In <span className="italic">‘Your Aircraft’.</span></p>
          </div>

        </div>
        <div className="box1 m-fix">
          <div className="div-img">
            <img className="config-img" src={logoim8} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>Travel in comfort in a highly sanitized space: </strong>Flying in  <span className="italic">‘Your Aircraft’</span> means goodbye to crowded airports & aircraft.</p>
          </div>

        </div>
        <div className="box1 m-fix">
          <div className="div-img">
            <img className="config-img" src={logoim4} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>Accessibility:</strong> Take advantage of 1000s of airports worldwide and 100s in Asia.</p>
          </div>

        </div>

        <div className="box1 m-fix">
          <div className="div-img">
            <img className="config-img" src={logoim7} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>At your service:</strong>  When you fly private, you don’t need to waste time at airport lounges or while away time between connecting flights.  </p>
          </div>

        </div>

        <div className="box1 m-fix">
          <div className="div-img">
            <img className="config-img" src={logoim12} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>Fly Private at a fraction of the cost of owning & operating one  </strong>  </p>
          </div>

        </div>
        <div className="box1 m-fix">
          <div className="div-img">
            <img className="config-img" src={logoim11} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>Fly with  those you choose to – your team, friends, or family: </strong>The only people inside  <span className="italic">‘Your Aircraft’</span>  will be the pilot, your family, your friends or your team. Discuss family matters without fear of a stranger overhearing your conversation or discuss marketing strategies with your team, knowing your competitor is nowhere near you. </p>
          </div>

        </div>
        <div className="box1 m-fix">
          <div className="div-img img6">
            <img className="config-img " src={logoim6} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>Your aircraft when you need it: </strong>Since you will be sharing the aircraft with other like-minded individuals, it is important  for you to book in advance so that the aircraft is ready when you  are. </p>
          </div>

        </div>
        <div className="box1 m-fix">
          <div className="div-img">
            <img className="config-img" src={logoim14} alt="new" />
          </div>
          <div className="new-text">
            <p><strong>Avoid typical airport problems: </strong> Stress is present in almost all phases of flying in an airline. Most airlines require passengers to arrive at the airport several hours in advance to check in. After check in, passengers will have to wait for their flight, which can be delayed, or worse, cancelled. On board the aircraft, discomfort can appear in the form of unruly passengers. Then, upon arrival- guess what -you can lose your luggage. When you fly in  <span className="italic">‘Private’</span>   you fly with people you want. On your  time. With minimal waiting.</p>
          </div>

        </div>
      </div>
    </>
  );
};

export default FractionalMembership;
