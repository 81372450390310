

// import React, { useState } from "react";
// import "../styles/Navbar.css";
// import { Link } from "react-router-dom";
// import logo from "../Assets/logo.png";
// import { FaBars, FaTimes } from "react-icons/fa";

// export default function Navbar() {
//   const [isMobile, setIsMobile] = useState(false);

//   const handleToggle = () => {
//     setIsMobile(!isMobile);
//   };
//   {
//     const [isOpen, setIsOpen] = useState(false);
//     const [scroll, setScroll] = useState(false);
//     const [underSec, setUnderSet] = useState("home");
//     // const menuRef = useRef(null);

//     const toggleMenu = () => {
//       setIsOpen(!isOpen);
//     };

//     const handleScroll = () => {
//       if (window.scrollY > 50) {
//         setScroll(true);
//       } else {
//         setScroll(false);
//       }
//     };

//     // const handleClickOutside = (event) => {
//     //   if (menuRef.current && !menuRef.current.contains(event.target)) {
//     //     setIsOpen(false);
//     //   }
//     // };

//     // // useEffect(() => {
//     // //   window.addEventListener("scroll", handleScroll);
//     // //   document.addEventListener("mousedown", handleClickOutside);

//     //   return () => {
//     //     window.removeEventListener("scroll", handleScroll);
//     //     document.removeEventListener("mousedown", handleClickOutside);
//     //   };
//     // }, []);

//     return (
//       <div>
//         <div className="mainnavbardiv">
//           <div className={isMobile ? "nav-links-mobile" : "leftdiv"}>
//             <Link className="leftdivcontent" to="/">
//               Home
//             </Link>
//             <div className="dropdown">
//               <Link className="leftdivcontent" to="/Aboutuss">
//                 About us
//               </Link>
//               <div className="dropdown-content">
//                 <Link to="/fractional">Message from the chairman</Link>
//                 <Link to="/membership">Vision & Mission</Link>
//                 <Link to="/charters">Team</Link>
//               </div>
//             </div>
//             <Link className="leftdivcontent" to="/whyprivatejet">
//               Why Private Jets
//             </Link>
//           </div>

//           <div className="centraldiv">
//             <img className="mylogo" src={logo} alt="Logo" />
//             <div className="hamburger-icon" onClick={handleToggle}>
//               {isMobile ? <FaTimes /> : <FaBars />}
//             </div>
//           </div>

//           <div className={isMobile ? "nav-links-mobile" : "rightdiv"}>
//             <div className="dropdown">
//               <Link
//                 className="rightdivcontent"
//                 onClick={() => {
//                   localStorage.setItem("nav","home");
//                   toggleMenu();
//                 }}
//                 to="/#program"
//               >
//                 Program
//               </Link>
//               <div className="dropdown-content">
//                 <Link to="/fractional">Fractional Membership</Link>
//                 <Link to="/membership">Charter</Link>
//               </div>
//             </div>
//             <Link   className="rightdivcontent" to="/">
//               Fleet
//             </Link>
//             <Link className="rightdivcontent" to="/">
//               Contact us
//             </Link>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

import React, { useState, useEffect, useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { FaBars, FaTimes } from 'react-icons/fa';
import "../styles/Navbar.css";
import logo from "../Assets/camjets_logo-white.png";
import { useLocation } from 'react-router';
// import logo from '../Assets/logooo.png';

const Navbar = () =>
{
  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [underSec, setUnderSet] = useState("home");
  const menuRef = useRef(null);
  const location = useLocation()
  const toggleMenu = () =>
  {
    setIsOpen(!isOpen);
  };

  const handleScroll = () =>
  {
    if (window.scrollY > 50)
    {
      setScroll(true);
    } else
    {
      setScroll(false);
    }
  };

  const handleClickOutside = (event) =>
  {
    if (menuRef.current && !menuRef.current.contains(event.target))
    {
      setIsOpen(false);
    }
  };

  useEffect(() =>
  {
    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () =>
    {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() =>
  {

    setUnderSet(localStorage.getItem('nav'))
  }, [location.pathname])
  useEffect(() =>
  {

    localStorage.getItem("nav", 'home')
    setUnderSet(localStorage.getItem('nav'))

  }, [])
  return (
    <nav className={scroll ? 'scrolled' : ''}>
      <div className="respo-nav">
        <div className={`hamburger ${ scroll ? 'scrolled' : '' }`} onClick={toggleMenu}>
          {isOpen ? <FaTimes className="icon" /> : <FaBars className="icon" />}
        </div>
        <Link to={"/"}>
          <div className="righty">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Link>
      </div>
      <div className={`mainnvbr fonts ${ isOpen ? 'open' : '' }`} ref={menuRef}>
        <Link style={{ marginRight: "1vh" }} onClick={() =>
        {
          localStorage.setItem("nav", "home")
          toggleMenu()
        }} to="/">
          {underSec === "home" ? <p style={{ textDecoration: "underline" }}>Home</p> : <p className='border'>Home</p>}
        </Link>
        <div className="dropdown">
          <Link onClick={() => localStorage.setItem("nav", "aboutus")
          } to="/aboutus">
            {underSec === "aboutus" ? <p style={{ textDecoration: "underline", left: "-10px" }}>About Us</p> : <p className='border'>About Us</p>}
          </Link>
          <div className="dropdown-content">
            <Link onClick={() =>
            {
              localStorage.setItem("nav", "aboutus")
              toggleMenu()
            }} className='under' to="/aboutus#chairman">Message from the Chairman</Link>
            {/* <Link className='under' to="/">Membership</Link> */}
            <Link className='under' to="/aboutus#vision" onClick={() => toggleMenu()}>Vision & Mission</Link>
            <Link className='under' to="/aboutus#team" onClick={() => toggleMenu()}>Team</Link>

          </div>
        </div>

        <Link onClick={() =>
        {
          localStorage.setItem("nav", "private")
          toggleMenu()
        }} to="/whyprivatejet">
          {underSec === "private" ? <p style={{ textDecoration: "underline" }}>Why Private Jets</p> : <p className='border'>Why Private Jets</p>}
        </Link>
        <Link className="click-home" to="/">
          <div className="center-new">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </Link>
        <div className="dropdown">
          <Link onClick={() => localStorage.setItem("nav", "prog")


          } to="/fractional">
            {underSec === "prog" ? <p style={{ textDecoration: "underline", left: "-10px" }}>Program</p> : <p className='border'>Program</p>}
          </Link>
          <div className="dropdown-content">
            <Link onClick={() =>
            {
              localStorage.setItem("nav", "prog")
              toggleMenu()
            }} className='under' to="/fractional">Fractional Membership</Link>
            {/* <Link className='under' to="/">Membership</Link> */}
            {/* <Link onClick={() =>
            {
              localStorage.setItem("nav","prog")
              toggleMenu()
            }} className='under' to="/fractional">Charters</Link> */}
          </div>
        </div>
        <div className="dropdown">
          <Link onClick={() =>
          {
            localStorage.setItem("nav", "fleet")
            toggleMenu()
          }


          } to="/fleet">
            {underSec === "fleet" ? <p style={{ textDecoration: "underline" }}>Fleet</p> : <p className='border'>Fleet</p>}
          </Link>
        </div>

        <Link onClick={() =>
        {
          localStorage.setItem("nav", "contactus")
          toggleMenu()
        }} to="/contact">
          {underSec === "contactus" ? <p style={{ textDecoration: "underline" }}>Contact Us</p> : <p className='border'>Contact Us</p>}
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;

