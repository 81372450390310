import React from "react";
import "../styles/StorageTimeline.css";
import { useNavigate } from "react-router-dom";
import logoim0 from "../Assets/economic.png";
import logoim1 from "../Assets/economic-growth.png";
import logoim2 from "../Assets/fly.png";
import logoim3 from "../Assets/tourism.png";
import logoim4 from "../Assets/private-jet.png";

const StorageTimeline = () => {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    // navigate('/why-private-jets');
    navigate("/Whyprivatejet");
  };

  return (
    <section className="why-private-jets">
      <div className="intro">
        <h1>Why Private Jets</h1>
      </div>
      <main>
        <article onClick={handleViewMoreClick} className="stop">
          <div className="text">
            <header className="header-sec">
              <img src={logoim0} alt="" className="icon-img" />
              <h2>Increasing Monetary Demands</h2>
            </header>
            <p>
              {" "}
              As Cambodia’s economy started to grow, the demand for
              international travel began to rise. With limited air connectivity
              for both people and cargo, the need for better market access
              became apparent.{" "}
            </p>
          </div>
        </article>
        <article onClick={handleViewMoreClick} className="stop">
          <div className="text">
            <header className="header-sec">
              <img src={logoim1} alt="" className="icon-img" />
              <h2>Economic Growth</h2>
            </header>
            <p>
            Business leaders faced challenges with limited flight schedules and long airport waits, making international business travel inefficient. This highlighted the need for more convenient and time-saving travel options, like private jets.
            </p>
          </div>
        </article>
        <article onClick={handleViewMoreClick} className="stop">
          <div className="text">
            <header className="header-sec">
              <img src={logoim2} alt="" className="icon-img" />
              <h2>Business Jets</h2>
            </header>

            <p>
            With the boom in luxury hotels, Cambodia's tourism industry saw
                a growing demand for high-end travel services. Private jets
                became essential to cater to wealthy travelers seeking exclusive
                experiences.
            </p>
          </div>
        </article>
        <article onClick={handleViewMoreClick} className="stop">
          <div className="text">
            <header className="header-sec">
              <img src={logoim3} alt="" className="icon-img" />
              <h2>Luxury Tourism</h2>
            </header>
            <p>
            As Cambodia continues to develop, the demand for business jets is expected to rise. Private jets offer the flexibility, efficiency, and luxury that are increasingly required by both business leaders and tourists.
            </p>
          </div>
        </article>
        <article onClick={handleViewMoreClick} className="stop">
          <div className="text">
            <header className="header-sec">
              <img src={logoim4} alt="" className="icon-img" />
              <h2>Future of Private Jets</h2>
            </header>
            <p>
            A study by Nexa Advisors revealed that companies using business
                jets grew their revenue by 23% more than non-users and saw an
                18% higher market capitalization. This underscores the
                importance of private jets in driving business success.
            </p>
          </div>
        </article>
      </main>
      {/* <button className="view-more-button" onClick={handleViewMoreClick}>
                View More
            </button> */}
    </section>
  );
};

export default StorageTimeline;
