import React from "react";
import happyimage from "../Assets/newimg.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";
import logoim0 from "../Assets/economic.png";
import logoim1 from "../Assets/economic-growth.png";
import logoim2 from "../Assets/fly.png";
import logoim3 from "../Assets/tourism.png";
import logoim4 from "../Assets/private-jet.png";
import back from "../Assets/Untitled design (11).png";
import whyprivatejetimg from "../Assets/whyprivatejetimg.png"
import whyprivatejetimg2 from "../Assets/whyprivatejetimg2.png"

import "../styles/Whyprivatejet.css";
export default function Whyprivatejet()
{
  return (
    <div>
      <Navbar />
      <div className="fractional-sec">
        <img src={back} alt="" className="img-fix" />
        <div className="overlay-fraction">
          <div className="textABC">
          <h2 className="who2">Why <span className="yellow2">Private Jets</span></h2>

          <p className="spann">
            Accelerating  Cambodia's Growth:  <span className="spann">The strategic advantage of private jets</span>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="firstbox2  "> */}
        {/* <div className="firstboxleft2  ">
      <img src={back} alt="" className="img-fixx" />
          <div className="whydiv">
            <p className="who2">Why <span className="yellow2">Private Jets</span></p>

          </div>
          <div className="elevatingdiv">
            <p >
            Accelerating  Cambodia's Growth:  <span className="spann">The strategic advantage of private jets</span>
            </p>
          </div>

        </div> */}
        {/* <div className="whyprivateimgdiv"> */}
        {/* <img className="whyprivatejetimg" src={whyprivatejetimg2} alt="" /> */}

        {/* </div> */}
      {/* </div> */}








      <div className="firstbox1">
        <img className="happyimg100" src={happyimage} alt="" />
        <div className="firstboxleft1">
          <h2>The need for business jets</h2>
          <p>
            As Cambodia’s economy grows so will the need of its people to travel across the world. <br />
            With limited air connectivity both for People and Cargo there is a need for far greater market access. <br />
            For business leaders in Cambodia, limited air connectivity coupled with schedules that are inconvenient and also long waits at airports makes it a gross waste of valuable time when they travel overseas. <br />
            In addition, as Cambodia’s tourism industry grows, so will the need for catering to the needs of the wealthy travellers. While there has been a boom in luxury hotels across the country, there is now a need for hi-end travel services, including Business Jets.

            <br /> <br />
            A study released in 2017 by Nexa Advisors, covering S&P 500 companies shows that over a five-year period, users of business aircraft grew their top line by a factor of 2.4 more than non-users on a weighted and indexed basis. <br />
            Further, private jet users out-performed non-users by 23 percent in revenue growth and users out-performed non-users by 18 percent in market capitalization.
          </p>
        </div>

      </div>

      <section className="why-private-jets">
        <div className="intro">
          <h1>Why Private Jets</h1>
        </div>
        <main>
          <article className="stop">
            <div className="text">
              <header className="header-sec">
                <img src={logoim0} alt="" className="icon-img" />
                <h2>Increasing Monetary Demands</h2>
              </header>
              <p>
                As Cambodia’s economy started to grow, the demand for
                international travel began to rise. With limited air
                connectivity for both people and cargo, the need for better
                market access became apparent. 
              </p>
            </div>
          </article>
          <article className="stop">
            <div className="text">
              <header className="header-sec">
                <img src={logoim1} alt="" className="icon-img" />
                <h2>Economic Growth</h2>
              </header>
              <p>
              Business leaders faced challenges with limited flight schedules and long airport waits, making international business travel inefficient. This highlighted the need for more convenient and time-saving travel options, like private jets.
               
              </p>
            </div>
          </article>
          <article className="stop">
            <div className="text">
              <header className="header-sec">
                <img src={logoim2} alt="" className="icon-img" />
                <h2>Business Jets</h2>
              </header>

              <p>
                With the boom in luxury hotels, Cambodia's tourism industry saw
                a growing demand for high-end travel services. Private jets
                became essential to cater to wealthy travelers seeking exclusive
                experiences.
              </p>
            </div>
          </article>
          <article className="stop">
            <div className="text">
              <header className="header-sec">
                <img src={logoim3} alt="" className="icon-img" />
                <h2>Luxury Tourism</h2>
              </header>
              <p>
              As Cambodia continues to develop, the demand for business jets is expected to rise. Private jets offer the flexibility, efficiency, and luxury that are increasingly required by both business leaders and tourists.
              </p>
            </div>
          </article>
          <article className="stop">
            <div className="text">
              <header className="header-sec">
                <img src={logoim4} alt="" className="icon-img" />
                <h2>Future of Private Jets</h2>
              </header>
              <p>
                A study by Nexa Advisors revealed that companies using business
                jets grew their revenue by 23% more than non-users and saw an
                18% higher market capitalization. This underscores the
                importance of private jets in driving business success.
              </p>
            </div>
          </article>
        </main>
        {/* <button className="view-more-button" onClick={handleViewMoreClick}>
                View More
            </button> */}
      </section>
      {/* <Footer /> */}
    </div>
  );
}
