import React from "react";
// import { Link } from 'react-router-dom';
import "../styles/heroo.css";
import { Link } from "react-router-dom";

const HeroSection = () =>
{
  return (
    <div className="wrapperh">
      <div className="hero-sec">
        <div className="topv">
          <video autoPlay loop muted playsInline className="video-fix desktop-video">
            <source src="/Website Camjets Hero section Video.mp4" type="video/mp4" />
          </video>
          <video autoPlay loop muted playsInline className="video-fix mobile-video">
            <source src="/Mobile Camjets Hero Section.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="overlay">

          <div className="line line-left"></div>
          <div className="center-content">
            <span className="pipe">|</span>
            <div className="textAb"> <span className="marginl">FLY </span> <br /> PRIVATE</div>
            <span className="pipe">|</span>
          </div>
          <div className="line line-right"></div>

        </div>
      </div>



    </div>
  );
};

export default HeroSection;




// <div id="text">
//             <h2>
//               Play Aviation
//             </h2>
//           </div>
//           <div id="line_r" className="line"></div>
//           <div id="line_l" className="line"></div>